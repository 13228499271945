import * as React from "react"
import { Typography } from '@material-ui/core';
import { graphql, Link } from "gatsby";

const HomePage = ({data}) => {
  const { allMarkdownRemark } = data;
  
  return (
    <div>
      <Typography>Welcome</Typography>
      <ul>
        {allMarkdownRemark.nodes && allMarkdownRemark.nodes.map(node => {
          return (
            <li><Link to={node.frontmatter.slug}>{node.frontmatter.month}/{node.frontmatter.year}: {node.frontmatter.title}</Link></li>
          )
        })}
      </ul>
    </div>
  )
}

export const query = graphql`
  query HomePageQuery {
    allMarkdownRemark(filter: {frontmatter: {order: {eq: 1}}}, sort: {fields: frontmatter___year, order: DESC}) {
      nodes {
        frontmatter {
          slug
          title
          month
          year
        }
      }
    }
  }
`;

export default HomePage;